@font-face {
  font-family: "Akkurat LL";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/AkkuratLLCyr-Light.woff") format("woff"),
  url("../fonts/AkkuratLLCyr-Light.woff") format("woff");
}

@font-face {
  font-family: "Akkurat LL";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/AkkuratLLCyr-Regular.woff") format("woff"),
  url("../fonts/AkkuratLLCyr-Regular.woff") format("woff");
}

@font-face {
  font-family: "AkkuratLL";
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/AkkuratLL-Regular.woff") format("woff"),
  ("./fonts/AkkuratLL-Regular.woff") format("woff");
}
