.project-description {
  position: relative;
  overflow: hidden;
  @include main-container-padding;
  padding-block: 76px 40px;
  background: $_main_light;

  @include media-tablet {
    padding-block: 108px 0;
  }

  @include media-desktop {
    padding-block: 52px 0;
  }

  @include media-desktop-wide {
    padding-block: 97px 0;
  }

  @include media-desktop-extra-wide {
    padding-block: 97px 0;
  }

}

.project-description__box {
  position: relative;
  @include media-tablet {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0px;
    column-gap: 50px;
    padding: 108px 40px 57px;
    background-color: transparent;
    z-index: 10;

    &::before {
      position: absolute;
      content: '';
      top: 55px;
      left: 0;
      width: 100%;
      height: 93vw;
      z-index: 5;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='%23003137' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2' transform='rotate(180)'%3E%3Cg stroke-width='0.1' fill='%23003137' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' stroke-width='0.1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-clip: content-box;
      background-color: transparent;
    }
  }

  @include media-desktop {
    gap: 80px;
    padding: 84px 60px 100px;

    &::before {
      top: 10px;
      left: 0
    }
  }

  @include media-desktop-wide {
    padding: 84px 80px 100px;

    &::before {
      top: -60px;
    }
  }

  @include media-desktop-extra-wide {
    padding-inline: 200px;
    margin: 0 auto;
    max-width: 1224px;

    &::before {
      top: -60px;
      left: 0;
    }
  }
}

.project-description__text {
  position: relative;
  margin: 0;
  padding: 0 0 36px;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet {
    color: $_main_light;
    width: 45%;
    z-index: 10;
  }

  @include media-desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.project-description__text--small {
  @include media-tablet {
    column-count: 2;
    column-gap: 50px;
    width: 95%;
  }

  @include media-desktop {
    column-gap: 80px;
  }
}

.project-description__text--large {
  padding: 0 0 32px;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 128%;
  letter-spacing: -1px;
  color: $_main_dark;

  @include media-tablet {
    width: 66%;

    font-size: 24px;
    color: $_main_light;
  }

  @include media-desktop {
    font-size: 32px;
  }
}
.project-description--project2 {
  background-color: $_main_dark;
  padding: 72px 16px 79px;
}

.project-description__text-project2,
.project-description__text--large-project2 {
  color: $_main_light;
}

.project-description__box.noaround:before
{
 display:none;
}
