.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 39px 0 39px 35px;
  margin: 0 0 0 16px;


  &::before {
    position: absolute;
    content: "";
    top: -0px;
    left: -35px;
    width: min(140vw, 700px);
    height: min(140vw, 700px);
    background-image: url("data:image/svg+xml, %3Csvg fill='none' stroke='%235D9F93' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.07' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' vector-effect='non-scaling-stroke' stroke-width='2px'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: -9px -9px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    transform: rotate(180deg);
    }

    @include media-tablet {
      &::before {
        width: min(957px, 130vw);
        height: min(957px, 130vw);
        top: -40px;
        left: -40px;
        width:125vw;
        height:125vw;
        background-position:0px 0px;
      }
    }

    @include media-desktop{
      &::before {
        width: 957px;
        height: 957px;
        left: -60px;
        top: -40px;
      }
    }

    @include media-desktop-wide{
      &::before {
        width: 1184px;
        height: 1184px;
      }
    }
}

.form__reason {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $_main_light;

  @include media-tablet {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }
}

.form__fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.form__legend {
  font-size: 0;
  padding: 0;
  margin: 0;
}

.form__input {
  position: relative;
  width: 100%;
  padding: 0 5px 0 5px;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 58px;
  color: $_white;
  letter-spacing: -1px;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid $_font_grass;

  z-index: 5;

  @include media-tablet {
    font-size: 20px;
    line-height: 69px;
  }

  &:invalid {
    border-bottom: 1px solid $_button_dark;
  }

  &:placeholder-shown {
    border-bottom: 1px solid $_font_grass;
  }

  &:focus {
    outline: 1px solid $_font_grass;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: 1px solid $_font_grass;
  }
}

.form__textarea::placeholder,
.form__input::placeholder {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 69px;
  letter-spacing: -1px;
  color: $_main_light;
  opacity: 0.4;

  @include media-tablet {
    font-size: 20px;
  }
}

.form__textarea {
  position: relative;
  padding: 0 5px 0 5px;
  margin-bottom: 27px;
  min-height: 70px;
  max-height: 420px;
  width: 100%;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $_white;
  letter-spacing: -1px;

  background-color: $_main_dark;
  border: none;
  border-bottom: 1px solid $_font_grass;
  resize: vertical;
  z-index: 5;

  @include media-tablet {
    margin-bottom: 64px;
    font-size: 20px;
    line-height: 69px;
  }

  &:focus {
    outline: 1px solid $_font_grass;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: 1px solid $_font_grass;
  }
}

.form__btn-submit {
  position: relative;
  width: 175px;
  padding: 12px 36px;

  background: $_button_dark;
  border-radius: 64px;
  border: none;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_white;
  cursor: pointer;
  z-index: 5;

  &:focus-visible {
    outline: 2px solid $_font_grass;
  }
}

@media screen and (max-width:500px)
{
.form__btn-submit {margin:auto;}}
