.project-2-description {
  position: relative;
  overflow: hidden;
  background-color: $_main_dark;
  @include main-container-padding;
  padding-block: 72px 79px;

  @include media-tablet {
    padding-block: 88px 0;
    background-color: $_main_light;

    &::before {
      position: absolute;
      content: '';
      top: 80px;
      left: -60px;
      width: 100%;
      height: 93vw;
      margin-left: 32px;
      z-index: 5;
      transform: rotate(180deg);

      background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='%23003137' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.1' fill='%23003137' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' stroke-width='0.1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 93vw;
      background-clip: content-box;
      /* background-color: $_main_dark; */
      background-color: transparent;
    }
  }

  @include media-desktop {
    padding-block: 100px 0;
  }

  @include media-desktop-wide {
    padding-block: 100px 0;
  }

  @include media-desktop-extra-wide {
    padding-block: 100px 0;
  }
}

.project-2-description__container {
  margin: 0 auto;
  max-width: 1224px;
  background-color: $_main_dark;

  @include media-tablet {
    padding: 49px 61px 74px 30px;
    background-color: transparent;
  }

  @include media-desktop {
    padding: 58px 78px 84px 60px;
  }

  @include media-desktop-wide {
    padding: 105px 237px 126px 79px;
  }
}

.project-2-description__box {
  @include media-tablet {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    padding: 34px 30px 57px;

    background-color: $_main_dark;
    background-color: transparent;
    z-index: 10;
  }

  @include media-desktop {
    gap: 80px;

    padding: 84px 60px 100px;
  }

  @include media-desktop-wide {
    padding: 84px 80px 100px;
  }
}

.project-2-description__wrapper {
  @include media-tablet {
    display: flex;
    gap: 50px;
  }
}

.project-2-description__text {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_light;

  @include media-tablet {
    color: $_main_light;
    width: 45%;
    z-index: 10;
  }

  @include media-desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.project-2-description__text--large {
  position: relative;
  padding: 0 0 32px;

  font-family: $_f__common;                                                                                    
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 128%;
  letter-spacing: -1px;
  color: $_main_light;

  @include media-tablet {
    width: 80%;
    color: $_main_light;
    font-size: 24px;
    z-index: 10;
  }

  @include media-desktop {
    font-size: 32px;
  }
}

.project-2-description__text-span:nth-child(2n-1) {
  @include media-tablet {
    display: block;
    margin-bottom: 20px;
  }
}
