.works {
  padding-top: 80px;
  background: $_main_light;

  @include media-tablet {
    padding-top: 100px;
  }
}

.works__container {
  margin: 0 auto;
  @include main-container-padding;
  padding-block: 26px 70px;
}

.works__articles-box {
  @include media-tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;
  }

  @include media-desktop {
    gap: 2.2%;
  }
}

.works__title {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  letter-spacing: -2px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 50px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    font-size: 55px;
  }
}
