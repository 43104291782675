.article {
  @include media-desktop  {
    width: 30%;
  }
}

.articles__container {
  margin: 0 auto;
}

.article:nth-child(5n),
.article:nth-child(5n-1) {
  @include media-desktop  {
    width: 45%;
  }
}
.article:not(:last-child) {
  margin-bottom: 60px;
}

.article:last-child {
  padding-bottom: 60px;
}

.article__image {
  display: block;
}

.article__title {
  margin: 10px 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 128%;
  letter-spacing: -1px;
  color: $_main_dark;

  @include media-tablet  {
    font-size: 34px;
    line-height: 38px;
    letter-spacing: -0.786px;
  }
}

.article__tags {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: $_secondary_dark;
  opacity: 0.7;

  @include media-tablet  {
    font-size: 16px;
    padding: 24px 0 0;
  }
}

.article__text {
  margin: 11px 0 18px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_secondary_black;

  @include media-tablet  {
    width: 100%;
    font-size: 18px;
    letter-spacing: -0.5px;
  }
}

.article__btn-link {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_button_dark;

  @include media-tablet  {
    font-size: 22px;
    letter-spacing: -1px;
  }

  @include media-desktop {
    font-size: 24px;
  }
}
// ---------------------------
// *** Big-article (First) ***
// ---------------------------
.article--big {
  width: 100%;
}

.article__box--big {
  display: flex;
  flex-direction: column;

  @include media-tablet  {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.article__title--big {
  margin: 10px 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 128%;
  letter-spacing: -1px;
  color: $_main_dark;

  @include media-tablet  {
    font-size: 32px;
  }
}

.article__tags--big {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: $_secondary_dark;
  opacity: 0.7;

  @include media-tablet  {
    font-size: 16px;
    padding: 24px 0 0;
  }
}

.article__text--big {
  margin: 11px 0 18px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_secondary_black;

  @include media-tablet  {
    width: 100%;
    font-size: 18px;
    letter-spacing: -0.5px;
  }
}

.article__link--big {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_button_dark;

  @include media-tablet  {
    font-size: 22px;
    letter-spacing: -1px;
  }

  @include media-desktop {
    font-size: 24px;
  }
}
