.client {
  background-color: $_secondary_dark;
}

.client__container {
  @include main-container-padding;
  padding-block: 82px 106px;

  @include media-tablet {
    margin:auto;
    padding-block: 113px 156px;
  }

  @include media-desktop {
    padding-block: 136px 190px;
  }
}

.client__content-box {
  @include media-desktop {
    display: flex;
    justify-content: space-between;
  }
}

.client__title {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  color: $_main_light;
  letter-spacing: -3px;

  @include media-tablet {
    font-size: 50px;
    line-height: 69px;
    letter-spacing: -3px;
  }
}

.client__text {
  margin: 0 0 82px;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_main_light;

  @include media-tablet {
    padding-top:31px;
    width:464px;

    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }

  @include media-desktop {
    width: 396px;
  }

  @include media-desktop-wide {
    width: 496px;
  }
}

.client__image-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px 50px;

  @include media-tablet {
    margin: 0 auto;
    gap: 110px 95px;
    width: 600px;
  }

  @include media-desktop {
    width: 986px;
  }

  @include media-desktop-wide {
    width: 1090px;
  }
}

.client__image {
  width: auto;
  height: 50px;
  display: block;
}
