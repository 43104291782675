.project-content {
  margin: 0 auto;
  background-color: $_white;
  background: linear-gradient(180deg, $_white 43%, rgba(225,241,227,1) 43%);

  @include main-container-padding;
}

.project-content__main-box {

  @include media-desktop-wide {
    max-width: 1920px;
    margin: 0 auto;
  }

  @include media-desktop-extra-wide {
    max-width: 1224px;
    margin: 0 auto;
  }
}

.project-content__box {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  gap: 10%;
}

.project-content__link-back {
  display: block;

  margin: 33px 0 43px;
  padding-left: 31px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_button_dark;

  @include media-tablet {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }

  @include media-desktop {

    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }
}

.project-content__box-back {
  position: relative;
}

.project-content__link-back-icon {
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 24px;
  height: 10px;

  @include media-desktop {
    top: 11px;
  }
}

.project-content__box-next,
.project-content__box-prev {
  position: relative;
  z-index: 5;
  background: linear-gradient(180deg,
    rgba(0, 49, 55, 0.5) 0%,
    rgba(0, 49, 55, 0.07) 100%);
    width: 35vw;

  &:hover {
    background: linear-gradient(180deg, $_main_dark 0%, rgba(0, 49, 55, 0.72) 100%);
  }
}


.project-content__link {
  position: relative;
  padding: 0;
  display: block;
}

.project-content__desc {
  position: absolute;
  top: 5px;
  left: 0;
}

.project-content__desc-text {
  margin: 5px 0  0 12px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  color: $_secondary_light;

  @include media-tablet {
    margin: 20px 0  0 24px;

    font-size: 14px;
  }

  @include media-desktop {
    margin: 30px 0  0 24px;

    font-size: 16px;
  }

  include media-desktop-wide {
    margin: 30px 0  0 30px;
  }
}

.project-content__desc-title {
  margin: 0 0 0 12px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  color: $_white;

  @include media-tablet {
    margin: 0 0  0 24px;

    font-size: 24px;
    line-height: 52px;
    letter-spacing: -1px;
  }

  @include media-desktop {
    font-size: 32px;
  }
}

.project-content__image {
  display: block;
  height: 100%;
}

.project-content__video {
  display: block;
  position: relative;
}

.project-content__icon-play {
  position: absolute;
  width: 45px;
  height: 45px;
  top: calc(50% - 23px);
  left: calc(50% - 23px);
  cursor: pointer;

  @include media-tablet {
    width: 60px;
    height: 60px;
  }

  @include media-desktop-wide {
    width: 72px;
    height: 72px;
  }
}

.project-content--project2 {
  background: linear-gradient(180deg, $_white 43%, $_white 43%);
  max-width: 1920px;
}
