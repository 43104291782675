.articles {
  padding-top: 80px;
  background: #E1F1E3;

  @include media-tablet  {
    padding-top: 100px;
  }
}

.articles__container {
  @include  main-container-padding;
}

.articles__box {

  @include media-tablet  {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap:  20px 0;
  }
}

.articles__title {
  margin: 0;
  padding: 18px 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  letter-spacing: -2px;
  color: $_main_dark;

  @include media-tablet  {
    padding: 44px 0;

    font-size: 50px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    padding: 54px 0;

    font-size: 55px;
  }
}
