.biomes__wrapper {
  background-color: $_main_dark;
}

.biomes__container {
  margin: 0 auto;
  max-width: 1920px;
  @include main-container-padding;
}

.biomes__box {
  position: relative;
  padding-block: 105px 95px;
  padding-right: 35px;

  @include media-tablet  {
    padding-block: 100px 169px;
    padding-right: 80px;
  }

  @include media-desktop {
    padding: 100px 155px 175px;
    padding-right: 132px;
  }

  @include media-desktop-extra-wide {
    padding: 100px 155px;
    padding-right: 208px;
  }

  @include media-desktop-extra-wide {
    padding: 100px 155px;
  }

  &::before {
    content: "";
      position: absolute;
      right: 16px;
      bottom: 70px;
      width: 1450px;
      height: 1450px;

      background-image: url("data:image/svg+xml, %3Csvg fill='none' stroke='%235D9F93' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.07' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' vector-effect='non-scaling-stroke' stroke-width='2px'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-position: bottom right;
      background-repeat:no-repeat;
      background-size: 1400px, 1400px;

        @include media-tablet  {
        bottom:80px;
        right: 32px;
        width:949px;
        height:949px;

        background-size:cover;
      }

      @include media-desktop  {
        right: 60px;
        width: 1382px;
        height: 1382px;

        background-size:cover;
      }

      @include media-desktop-wide  {
        right: 40px;
        width: 1582px;
        height: 1382px;
      }

      @include media-desktop-extra-wide  {
        right: -50px;
      }
  }
}

.biomes__title {
  margin: 0 0 32px 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 54px;
  letter-spacing: -3px;
  color: $_main_light;

  @include media-tablet  {
    margin-bottom: 4.65;
    padding-top: 32px;

    font-size: 75px;
    line-height: 81px;
    letter-spacing: -5px;

  }

  @include media-desktop {
    padding-top: 65px;

    font-size: 96px;
    line-height: 100px;
  }

  @include media-desktop-wide {
    padding-top: 57px;
  }
}

.biomes__text {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $_main_light;

  @include media-tablet {
    width: 544px;
    margin: 0 auto;
  }

  @include media-desktop {
    width: 600px;
    margin: 0;
    margin-left: 160px;
  }

  @include media-desktop-wide {
    margin-left: 416px;
  }
}

.biomes__text:not(:last-child) {
  margin-bottom: 18px;
}

.biomes__image-box {
  padding: 0 16px;
  background: linear-gradient(180deg,#e1f1e3 79%,#ffffff 79%);

  @include media-tablet {
    padding: 0 32px;
    background: linear-gradient(180deg,#e1f1e3 60%,#ffffff 60%);
  }

  @include media-desktop {
    padding: 0 20px;
    background: linear-gradient(180deg,#e1f1e3 81%,#ffffff 81%);
  }

  @include media-desktop-wide {
    padding: 0 108px;
  }
}

.biomes__img {
  display: block;
  max-height: 100vh;
}

.biomes__image {
  display: block;
  margin: 0 auto;

  @include media-tablet {
    max-width: 704px;
  }

  @include media-desktop {
    max-width: 984px;
  }

  @include media-desktop-wide {
    max-width: 1016px;
  }
}

.biomes__holder {
  background-color: $_main_light;
}

.biomes__container--holder {
  padding: 80px 16px 75px;

  @include media-tablet {
    padding: 91px 32px;
  }

  @include media-desktop {
    padding: 130px 20px 153px;
  }

  @include media-desktop-wide {
    padding: 95px 108px 105px;
  }

  @include media-desktop-extra-wide {
    padding: 95px 350px 105px;
  }
}

.biomes__heading {
  margin: 0 0 28px;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 36px;
  letter-spacing: -2px;
  color: $_main_dark;

  @include media-tablet {
    width: 630px;
    margin-bottom: 59px;

    font-size: 50px;
    line-height: 56px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    width: 650px;
    margin-bottom: 0;

    line-height: 69px;
  }

  @include media-desktop-wide {
    margin-bottom: 25px;
  }
}

.biomes__lead {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet  {
    width: 500px;
    margin-left: auto;

    font-size: 18px;
    line-height: 24px;
  }

  @include media-desktop {
    margin-right: 64px;
    width: 650px;

    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }

  @include media-desktop-wide {
    margin-right: 305px;
  }
}

.biomes__text-box {
  background-color: $_white;
}

.biomes__container--text {
  padding: 36px 16px;
  display: flex;
  flex-direction: column;
  gap: 23px;

  @include media-tablet {
    gap: 24px;
    flex-direction: row;
    justify-content: center;
    width: 704px;
    margin: 0 auto;

    padding: 91px 0 101px;
  }

  @include media-desktop {
    padding: 97px 0;
    width: 984px;
  }

  @include media-desktop-wide {
    width: 1016px;
    padding: 111px 0 153px;
    gap: 67px;
  }
}

.biomes__desc {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 18px;
    line-height: 24px;
  }

  @include media-desktop {
	column-count:2;
  }
}

.biomes__features {
  position: relative;
  overflow: hidden;


  @include media-tablet  {

    &::before {
      position: absolute;
      content: '';
      top: -2px;
      left: -2px;
      width: 912px;
      height: 912px;
      margin-left: 32px;

      transform: rotate(180deg);
      z-index: 5;


      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.1' style='fill: %23e1f1e3' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' stroke-width='0.1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: 912px 912px;
      background-clip: content-box;
    }
  }

  @include media-desktop {
    &::before {
      width: 982px;
      margin-left: 20px;
      background-size: 982px 982px;
      background-position: 0px -70px;
    }
  }

  @include media-desktop-wide {


    &::before {
      width: 1224px;
      margin-left: 108px;
      background-size: 1224px 1224px;
      background-position: 0px -310px;
    }
  }

  @include media-desktop-extra-wide {
    &::before {
      margin-left: 21%;
    }
  }
}

.biomes__container-features {
  @include main-container-padding;
  background-color: $_main_light;

  @include media-tablet {
    background-color: transparent;
  }
}

.biomes__wrapper-features {
  padding-block: 32px 66px;
  z-index: 10;

  @include media-tablet {
    overflow: hidden;
    position: relative;
    padding: 45px 8px 50px 40px;
  }

  @include media-desktop {
    padding: 58px 141px 66px 60px;
  }

  @include media-desktop-wide {
    padding: 86px 208px 90px 104px;
  }
}

.biomes__descr-text {
  margin: 0 0 12px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 128%;
  letter-spacing: -1px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 28px;
  }

  @include media-desktop {
    font-size: 32px;
    margin: 0 0 19px;
  }
}

.biomes__subtitle {
  margin: 0 0 67px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 36px;
  letter-spacing: -2px;
  color: $_main_dark;

  @include media-tablet {
    width: 600px;
    margin-bottom: 64px;

    font-size: 50px;
    line-height: 53px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    margin-bottom: 45px;
    font-size: 55px;
    line-height: 69px;
  }
}

.biomes__feature-text {
  margin: 0 0 16px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet {
    margin: 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.biomes__item {
  width: 308px;

  @include media-tablet {
    width: 304px;
  }

  @include media-desktop {
    width: 336px;
  }

  @include media-desktop-wide {
    width: 390px;
  }
}

.biomes__item-title {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 24px;
  }
}

.biomes__list-features {
  @include list-style-reset;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 26px;

  @include media-tablet {
    gap: 60px 30px;
  }

  @include media-desktop {
    gap: 40px 84px;
    width: 800px;
  }

  @include media-desktop-wide {
    gap: 40px 130px;
    width: 1000px;
  }
}

.biomes__item-text {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 18px;
  }
}
