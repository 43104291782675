.header {
  background: linear-gradient(180deg, rgba(0, 49, 55, 0.81) 0%, $_main_dark 100%);
  position:fixed;
  top:0px;
  z-index:900;
  width:100%;
  height: 81px;

  @include media-tablet {
    height: 100px;
  }

    @include media-desktop  {
      background:linear-gradient(180deg, rgba(0, 49, 55, 0.4) 0%, rgba(0, 49, 55, 0) 90.32%);
  }
}

.header--green {
  background: linear-gradient(180deg, rgba(0, 49, 55, 0.81) 0%, $_main_dark 100%);
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  min-width: $mobile;
  max-width: $mobile-container-max;
  height: 80px;
  margin: 0 auto;
  @include main-container-padding;
  padding-block: 31px 29px;

  @include media-tablet {
    height: 100px;
    min-width: $tablet;
    max-width: $tablet-container-max;
    padding-block: 40px;
  }
  @include media-desktop {
    height: 100px;
    min-width: $desktop;
    max-width: $desktop-container-max;
  }

  @include media-desktop-wide {
    min-width: $desktop-wide;
    max-width: $desktop-wide-container-max;
  }
}

.header__logo {
  align-self: center;
  min-width: 124px;
  min-height: 15px;

  @include media-tablet  {
    min-width: 135px;
    min-height: 17px;
  }

  @include media-desktop {
    min-width: 159px;
    min-height: 21px;
  }
}

.header__toggle {
  width: 35px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @include media-tablet  {
    width: 37px;
    height: 24px;
  }
}
