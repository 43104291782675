.main-about {
  margin: 0;
  padding: 80px 0 0;
  background-color: $_main_dark;
  overflow: hidden;

  @include media-desktop {
    width: 50%;
  }
}

.main-about__box {
  @include media-desktop {
    display: flex;
  }
}

.main-about__container {
  position: relative;
  @include main-container-padding;

  &::before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: -80px;
    width: 100%;
    height: 440px;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23F3F1D8' stroke='%23F3F1D8' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0 0 26 26'%3E%3Cg stroke-width='0.1' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' stroke-width='0.1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat:no-repeat;
    background-position: top -20px left -80px;
    background-size:440px auto;
    z-index: 5;

    @include media-tablet {
      width: 100%;
      height: 800px;
      background-size:800px 900px;
      background-position: top -300px left -40px;
    }

    @include media-desktop {
      left: -30px;
      background-position: top -250px left -175px;
      background-size:830px 820px;
    }

    @include media-desktop-wide {
      padding-inline: 100px;
      background-position: top -250px left 10px;
    }

    @include media-desktop-extra-wide {
      background-position: top -250px left 180px;
    }
  }

  @include media-desktop-extra-wide {
    padding-left: 348px;
    padding-right: 50px;
  }
}

.main-about__title {
  position: relative;
  margin: 0;
  padding-block: 56px 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  letter-spacing: -2px;
  color: $_secondary_dark;

  z-index: 10;

  @include media-tablet {
    padding: 71px 0 46px 30px;

    font-size: 55px;
    letter-spacing: -3px;
  }
}

.main-about__text {
  position: relative;
  width: 262px;
  margin: 0;
  padding: 0 0 71px 16px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $_main_dark;

  z-index: 10;

  @include media-tablet {
    width: 630px;
    padding: 0 0 95px 184px;

    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }

  @include media-desktop {
    width: 90%;
    padding-inline: 40px;
    padding-bottom: 75px;
  }

  @include media-desktop-wide {
    width: 630px;
  }

  @include media-desktop-extra-wide {
    width: 600px;
    padding-inline: 0 30px;
    padding-bottom: 75px;
  }
}
