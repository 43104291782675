.magazine {
  background-color: $_white;
}

.magazine__container {
  margin: 0 auto;
  @include main-container-padding;
  padding-block: 72px 147px;

  @include media-tablet {
    padding-block: 127px 166px;
  }

  @include media-desktop {
    padding-block: 130px;
  }
}

.magazine__title {
  margin: 0 0 69px;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  color: $_secondary_black;
  letter-spacing: -2px;

  @include media-tablet {
    font-size: 50px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    font-size: 55px;
  }
}

.magazine__list {
  @include list-style-reset;

  @include media-tablet {
    width: 58%;
  }
}

.magazine__item {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $_font_grass;
}

// .magazine__item:first-child {
//   border-top: 1px solid $_font_grass;
// }

.magazine__item--active {
  background-color: $_main_light;
  margin-inline: -15px;
  padding-inline: 15px;
  border-top: none;
  border-bottom: none;
}

.magazine__item-name,
.magazine__download {
  display: block;
}

.magazine__item-name {
  width: 60%;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
  color: $_secondary_black;
  letter-spacing: -0.5px;

  @include media-tablet {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -1px;
  }
}

.magazine__link {
  position: relative;
  padding-right: 18px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_button_dark;

  &::before {
    position: absolute;
    content: "";
    right: 0;
    top: 2px;
    width: 13px;
    height: 15px;
    background-image: url('../img/download_sign.svg');
    background-repeat: no-repeat;
    background-size: 13px 15px;
  }

  @include media-tablet {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;

    &::before {
      right: -5px;
      top: 8px;
      width: 20px;
      height: 23px;
      background-image: url('../img/download_sign-tablet.svg');
    }
  }
}

.magazine__list-box {
  @include media-tablet {
    display: flex;
    gap: 8%;
  }

}

.magazine__brochure {
  display: none;

  @include media-tablet {
    display: block;
    min-width: 30%;
  }
}

.magazine__list-box--biomes {
  margin-bottom: 108px;
}
