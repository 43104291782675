// Media
$mobile: 360px;
$mobile-container-max: 560px; // максимальная ширина контейнера на мобилке

$tablet: 768px;
$tablet-container-max: 920px; // максимальная ширина контейнера на планшете

$desktop: 1024px;
$desktop-container-max: 1439px; // максимальная ширина контейнера на десктопе

$desktop-wide-container-max: 1920px; // максимальная ширина контейнера на широком десктопе
$desktop-wide: 1440px;

$desktop-extra-wide: 1920px;

// Colors

$_white: #ffffff;
$_black: #000000;
$_main_light: #e1f1e3;
$_main_dark: #003137;
$_secondary_black: #151919;
$_secondary_dark: #254634;
$_secondary_light: #F3F1D8;
$_button_dark: #E0804E;
$_button_hover: #FAB38E;
$_button_click: #deb29a;
$_font_grass: #5D9F93;

// Ретина
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// Fonts
$_f__common_name: "Akkurat LL";
$_f__default: "Helvetica", "Arial", sans-serif;
$_f__common: $_f__common_name, $_f__default;
