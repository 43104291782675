.contacts {
  padding-top: 80px;
  position: relative;
  background-color: $_main_dark;
  overflow: hidden;
}

.contacts__container {
  margin: 0 auto;
  @include main-container-padding;
  padding-block: 0;

    @include media-tablet {
    padding-block: 64px 0;
  }

  @include media-desktop{
    display:flex;
    flex-direction: row;
    flex-flow: row wrap;
  }
}

.contacts__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  letter-spacing: -0.5px;

  @include media-tablet {
    margin-bottom: 80px;
  }

  @include media-desktop{
    width:300px; display:flex;
    flex-direction: column;
  }
}

.contacts__title {
  margin: 0 0 10px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  color: $_main_light;
  letter-spacing: -2px;

  @include media-tablet {
    font-size: 50px;
    letter-spacing: -3px;
  }

  @include media-desktop{
    font-size: 55px;
    width:100%;
    padding-bottom:50px;
  }
}
.contacts__form {
  @include media-desktop{
    width:500px;
  }
}
