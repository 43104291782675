.about-work {
  background-color: $_main_light;
}

.about-work__item,
.about-work__title,
.about-work__text,
.about-work__slogan {
  color: $_main_dark;
}
