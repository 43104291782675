.footer {
  background-color: $_secondary_black;
}

.footer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  min-height: 250px;
  margin: 0 auto;

  @include main-container-padding;
  padding-block: 32px 27px;

  @include media-tablet {
    justify-content: unset;
    padding-block: 81px 57px;
  }
}

.footer__copyright {
  position: absolute;
  bottom: 25px;
  left: 16px;
  display: block;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $_main_light;

  @include media-tablet {
    position: static;

    font-size: 16px;
    line-height: 24px;
  }
}

.footer__logo {
  display: flex;
  width: 100%;
  margin-right: auto;

  @include media-tablet {
    flex-direction: column;
    gap: 11px;
    width: 150px;
    margin-right: 0 auto;
  }
}

.footer__logo-link {
  width: 88px;
  height: 30px;
}

.footer__address {
  @include media-tablet {
    margin-right: 73px;
  }
}

.footer__address-title {
  margin: 0 0 5px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  color: $_main_light;
}

.footer__address-list {
  @include list-style-reset;
}

.footer__address-link {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
  color: $_main_light;
}

.footer__social-list {
  @include list-style-reset;
}

.footer__social-title {
  margin: 0 0 5px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  color: $_main_light;
}

.footer__social-link {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
  color: $_main_light;
}



[dir=rtl] .navigation__list
{ 
  padding:21px 65px 0 0px
}

[dir=rtl] .construction__text::before
{
 
 }
@media screen and (min-width: 768px)
{
[dir=rtl] .construction__text::before
{
transform: scale(-1,1);
}
[dir=rtl] .about-history__list--light::before
{ transform:none !important;

}
}
[dir=rtl] .about-map__pin--3,
[dir=rtl] .about-map__pin--2,
[dir=rtl] .about-map__pin--1
{
 right: auto !important;
}
[dir=rtl] .about-map__pin--3:not(:hover)
{
 left: 40% !important;
}
[dir=rtl] .about-map__pin--3:hover
{
 left: 33.5% !important;
}

[dir=rtl] .about-map__pin--1:not(:hover)
{
 left: 45.6% !important;
}
[dir=rtl] .about-map__pin--1:hover
{
 left: 39.1% !important;
}

[dir=rtl] .about-map__pin--2:hover
{
 left: -3% !important;
}
[dir=rtl] .about-map__pin--2:not(:hover)
{
 left: 3.3% !important;
}

[dir=rtl] .about-opportunities__container,
[dir=rtl] .about-opportunities
{background-image:none !important;
}

@media screen and (min-width: 768px)
{
[dir=rtl] .biomes__features::before
{
transform: rotate(180deg) scale(-1,1) !important;
 }}
 
[dir=rtl] .slick-prev
{
 right: 0px !important;
 left: auto !important;
}
[dir=rtl] .slick-next
{
 right: auto !important;
 left: 0px !important;
}

.contacts__form 
{
min-height:500px;
}