.articles-about-us {
  background-color: $_main_dark;
  position: relative;

  @include media-desktop {
    width: 50%;
  }

  &::before {
    @include media-desktop {
      position: absolute;
      display: none;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
      z-index: 5;
      background:
      linear-gradient(to top, #003137 6.48%, rgba(0, 49, 55, 0) 62.62%);
    }
  }
}

.articles-about-us--gradient-visible {
  &::before {
    @include media-desktop {
      display: block;
    }
  }
}

.articles-about-us__container {
  padding: 39px 16px 45px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;

  @include media-tablet {
    padding-inline: 32px;
  }

  @include media-desktop {
    position: relative;
    padding: 170px 20px 0;
    height: 660px;
  }

  @include media-desktop-wide {
    padding-inline: 20px 108px;
    height: 740px;
  }

  @include media-desktop-extra-wide {
    padding-inline: 20px 350px;
    height: 790px
  }
}
