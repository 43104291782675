.project-2 {
  background-color: $_main_dark;
}

.project-2__container {
  margin: 0 auto;
  max-width: 1224px;
  @include main-container-padding;

  @include media-desktop-extra-wide {
    max-width: 1920px;
  }
}

.project-2__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  padding: 125px 0 60px;

  @include media-tablet {
    padding: 125px 0 75px;
  }

  @include media-desktop {
    padding: 145px 0 55px;
  }

  @include media-desktop-wide {
    padding-bottom: 75px;
  }
}

.project-2__title {
  margin: 0;
  padding-bottom: 43px;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  letter-spacing: -1px;
  color: $_main_light;

  background-color: $_main_dark;

  @include media-tablet {
    padding-bottom: 100px;

    font-size: 65px;
    line-height: 69px;
    letter-spacing: -3px;

  }

  @include media-desktop {
    padding-bottom: 145px;

    font-size: 96px;
    line-height: 100px;
    letter-spacing: -5px;
  }
}

.project-2__container-picture {

  @include media-tablet {
    display: flex;
  justify-content: space-between;
  gap: 24px;

  width: 100%;
  }

  @include media-desktop {
    gap: 85px;
  }

  @include media-desktop-wide {
    gap: 104px;
  }
}

.banner-project-2__image--left {
  display: block;
}

.project-2__image-right {
  display: none;

  @include media-tablet {
    display: block;
    margin-top: -180px;
  }

  @include media-desktop {
    margin-top: -250px;
  }

  @include media-desktop-wide {
    margin-top: -265px;
  }
}

.project-2__banner-picture {
  display: block;
}

.project-2__box-info {
  border-top: 2px solid rgba(225, 241, 227, 0.6);
  padding: 53px 0 68px;

  @include media-tablet {
    padding: 44px 0px 60px;

    display: flex;
    flex-wrap: wrap;
  }

  @include media-desktop {
    padding: 68px 0px 85px;
  }

  @include media-desktop-wide {
    padding: 110px 0px 130px;
  }
}

.project-2__info {
  align-items: baseline;
  margin: 0;
  margin-bottom: 31px;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  color: $_main_light;

  @include media-tablet {
    margin-bottom: 60px;
    width: 60%;
  }

  @include media-desktop {
    width: 70%;
  }
}

.project-2__feature-box {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include media-desktop {
    width: 23%;
  }
}

.project__feature-box--order {
  @include media-desktop {
    order: -1;
  }
}

.project-2__item-info {
  margin: 0;
  padding: 0;
 white-space:nowrap;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 52px;
  letter-spacing: -1px;
  color: $_main_light;

  @include media-tablet {
    font-size: 32px;
  }

  @include media-desktop {
    font-size: 36px;
  }
}

.project-2__feature {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-transform: lowercase;
  color: $_main_light;

  @include media-tablet {
    font-size: 14px;
  }

  @include media-desktop {
    font-size: 16px;
  }
}

.project-2__list-of-works {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @include list-style-reset;
  padding-bottom: 53px;
  justify-content: center;

  color: $_main_light;

  @include media-tablet {
    gap: 6px;
    padding-bottom: 110px;
    width:30%;
  }

  @include media-desktop {
    padding-bottom: 34px;
  }

  @include media-desktop-wide {
    padding-bottom: 43px;
  }
}

.project-2__item-of-works {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 149%;
  color: $_main_light;

  @include media-tablet {
    font-size: 16px;
  }

  @include media-desktop {
    font-size: 18px;
    letter-spacing: -1px;
  }
}

.project-2__text-box {
  width: 100%;
  padding: 56px 0 0;

  border-top: 2px solid rgba(225, 241, 227, 0.6);

  @include media-tablet {
    display: flex;
    gap: 80px;
    padding: 60px 0 0;
  }

  @include media-desktop {
    display: flex;

    padding: 68px 0 0;
  }

  @include media-desktop-wide {
    padding: 110px 0 0;
  }
}

.project-2__text {
  margin: 0;
  padding: 0;

  color: $_white;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.project-2__text--small {
  padding: 0 35px 33px 0;

  @include media-tablet {
    width: 210px;
  }

  @include media-desktop {
    width: 312px;
  }
}

.project-2__text--large {
  font-weight: 300;
  font-size: 22px;
  line-height: 128%;
  letter-spacing: -1px;
  color: $_white;

  @include media-tablet {
    width: 415px;
    margin-left: auto;

    font-size: 28px;
    line-height: 128%;
  }

  @include media-desktop {
    width: 565px;

    font-size: 32px;
  }

  @include media-desktop-wide {
    width: 705px;
  }
}
