.big-work-card {
  margin-bottom: 40px;
}

.big-work-card__desc {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: $_main_dark;

  @include media-tablet {
    font-size: 16px;
  }
}

.big-work-card__subject {
  margin: 0;
  padding: 0;
}

.big-work-card__link {
  display: block;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 69px;
  letter-spacing: -1px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 35px;
    letter-spacing: -2px;
  }

  @include media-desktop {
    font-size: 55px;
    letter-spacing: -3px;
  }
}

.big-work-card__link--image {
  margin-bottom: 14px;
}

.big-work-card__image {
  display: block;
}

.big-work-card__address {
  margin: 0 0 14px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $_secondary_dark;
  opacity: 0.7;

  @include media-tablet {
    font-size: 16px;
  }
}

.big-work-card__text {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_secondary_black;

  @include media-tablet {
    width: 600px;
  }

  @include media-desktop {
    width: 592px;
  }
}
