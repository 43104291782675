.small-work-card:not(:last-child) {
  margin-bottom: 40px;
}

.small-work-card__box {
  display: flex;
  justify-content: space-between;
}

.small-work-card {

  @include media-tablet {
    width: 43%;
  }

  @include media-desktop {
    width: 31%;
  }
}

.small-work-card--1 {
  order: 1;
}

.small-work-card--2 {
  order: 2;
}

.small-work-card--3 {
  order: 3;
  @include media-tablet {
    order: 4;
  }

  @include media-desktop {
    order: 3;
  }
}

.small-work-card--4 {
  order: 4;

  @include media-tablet {
    order: 3;
  }

  @include media-desktop {
    order: 4;
  }
}

.small-work-card--5 {
  order: 5;
}

.small-work-card--6 {
  order: 6;
}

.small-work-card__desc {
  margin: 0;
  padding: 5px 0 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: $_main_dark;

  @include media-tablet {
    font-size: 14px;
  }

  @include media-desktop {
    font-size: 16px;
  }
}

.small-work-card__subject {
  margin: 0;
  padding: 5px 0 0 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $_main_dark;
}

.small-work-card__link {
  position: relative;
  display:block;
  width: 100%;
  overflow:hidden;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $_main_dark;
}

.small-work-card__image {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}

.small-work-card__button {
    background: transparent;
    position: absolute;
    top: calc(50% - 25px);
    z-index: 100;
    border: 1px solid $_white;
    cursor: pointer;
    visibility: hidden;
    left: 0;
    right: 0;
    margin-inline: auto;
}

.small-work-card__link:hover .small-work-card__button {
  visibility: visible;
}

.small-work-card__link:hover .small-work-card__image {
  transition-duration: 0.3s;
  filter: blur(17px);
}

.small-work-card__address {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: $_secondary_dark;
  opacity: 0.7;

  @include media-tablet {
    font-size: 14px;
  }
}
