.work {
  z-index:5;
  position:relative;
  background-color: $_main_dark;
  overflow: hidden;
}

.work__container {
  margin: 0 auto;
  @include main-container-padding;
  padding-block: 23px 54px;

  @include media-tablet {
    padding-block: 52px 100px;
  }

  @include media-desktop {
    display: flex;
    flex-wrap: wrap;
    justify-items: start;

    padding-block: 52px 57px;
  }
}

.work__container--services {
  @include media-desktop {
    padding-block: 152px 57px;
  }
}

.work__slogan {
  position: relative;
  margin: 0;
  padding: 0 0 70px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 55px;
  line-height: 58px;
  color: $_main_light;
  letter-spacing: -3px;

  @include media-tablet {
    width:670px;
    font-size:85px;
    line-height: 100px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    width:704px;
    font-size:96px;
    padding-bottom:128px;
  }

  &::before {
    position: absolute;
    content: '';
    bottom: 30px;
    right: 0px;
    width: min(130vw,750px);
    height: min(130vw,750px);
    background-image: url("data:image/svg+xml, %3Csvg fill='none' stroke='%235D9F93' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.07' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' vector-effect='non-scaling-stroke' stroke-width='2px'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;

    @include media-tablet {
      width: 1184px;
      height: 1184px;
    }

    @include media-desktop {
      width: 1184px;
      height: 1184px;
      bottom: 75px;
    }
  }
}

.work__text {
  margin: 0;
  padding: 0 0 28px;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_main_light;


  @include media-tablet {
    width:55%;
    margin-inline-start: auto;
    margin-inline-end: 0;

    font-size:18px;
    letter-spacing: -0.5px;
  }

  @include media-desktop {
    width: 25vw;
    margin-inline-start: auto;
    margin-inline-end: 0;
    letter-spacing:-0.5px;
  }

  @include media-desktop-wide {
    width: 20vw;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }
}

.work__title {
  margin: 0;
  padding: 0 0 7px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 52px;
  color: $_main_light;
  letter-spacing: -1px;

  @include media-tablet {
    font-size: 32px;
    width: 100%;
  }
}

.work__list {
  @include list-style-reset;

  @include media-tablet {
    columns: 2;
  }

  @include media-desktop {
    grid-column: 1 / 3;
    width: 100%;
  }
}

.work__item {
  padding: 20px 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $_main_light;
  letter-spacing: -1px;

  border-bottom: 1px solid $_font_grass;
}
/*
.work__item:hover {
  border-bottom: 1px solid rgba(93, 159, 147, .5);

  &:work__item--no-border-bottom {
    border-bottom: none;
  }
}*/

.work__item:last-child {
  border-bottom: none;
}

.work__item--no-border-bottom {
  @include media-tablet {
    border-bottom: none;
  }
};

.work__item-image {
  display: none;
}
/*
.work__item:hover .work__item-image {
  @include media-desktop {
    display: block;
    position: absolute;
    right: 217px;
    top: 130px;
    width: 387px;
    height: auto;
    z-index: 10;
  }
}*/
