.construction {
  background-color: $_main_light;
  overflow: hidden;
}

.construction__container {
  position: relative;
  margin: 0 auto;
  @include main-container-padding;
  padding-block: 12px 50px;

  @include media-tablet {
    padding-block: 74px 42px;
  }

  @include media-desktop {
    display: grid;
    justify-items: start;
    grid-template-columns: 2fr 1fr;
    padding-block: 62px 64px;
  }
}

.construction__slogan {
  margin: 0 0 43px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 55px;
  line-height: 58px;
  color: $_main_dark;
  letter-spacing: -3px;

  @include media-tablet {
    width:630px;
    font-size: 85px;
    line-height: 100px;
  }

  @include media-desktop {
    width:710px;
    font-size: 96px;
  }

}

.construction__text {
  position: relative;
  margin: 0 0 73px;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_main_dark;
  letter-spacing: -0.5px;

  &::before {
    position: absolute;
    content: '';
    left: calc(50% - 700px);
    bottom: -50px;
    width: 1400px;
    height: 1400px;
    background-image: url("data:image/svg+xml, %3Csvg fill='none' stroke='%235D9F93' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.07' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' vector-effect='non-scaling-stroke' stroke-width='2px'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: top left;
    transform: rotate(180deg);
  }

  @include media-tablet {
    width:55%;
    margin-inline: auto 0;
    padding-right:10px;

    font-size: 18px;

    &::before {
      width: min(1020px, 120vw);
      height: min(1020px, 120vw);
      transform:none;
      left:auto;
      right:0px;
    }
  }

  @include media-desktop {
    width:228px;

    &::before {
      width: 1184px;
      height: 1184px;
      left: -180px;
      right: auto;
      bottom: -120px;
    }
  }

  @include media-desktop-wide {
    width: 410px;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }
}

.construction__title {
  margin: 0 0 22px;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 52px;
  color: $_main_dark;
  letter-spacing: -1px;

  include media-tablet {
    font-size: 32px;
  }
}

.construction__list {
  @include list-style-reset;
  width: 100%;

  @include media-tablet {
    columns: 2;
  }

  @include media-desktop {
    grid-column: 1/3;
    columns: 3;
  }

}

.construction__item {
  padding: 17px 0;
  border-bottom: 1px solid #5D9F93;
  letter-spacing: -0.5px;
}

.construction__item:last-child {
  border-bottom: none;
}

@include media-tablet {



}

@include media-desktop {

  .construction__title
  {
    width: 100%;
    grid-column: 1/3;
  }
}

.construction__item--no-border-bottom {
  @include media-tablet {
    border-bottom: none;
  }
};