.social__list {
  @include list-style-reset;
}

.social__title {
  margin: 0 0 5px;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  color: $_main_light;

  @include media-tablet {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
  }
}

.social__link {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
  color: $_main_light;

  @include media-tablet {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -1px;
  }
}
