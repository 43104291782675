.projects {
  position:relative;
  @include main-container-padding;
  margin: 0 auto;
  padding-block: 53px 90px;
  background:$_white;

  @include media-tablet  {
    padding-block: 95px 117px;
  }

  @include media-desktop {
    padding-block: 95px 134px;
  }

  @include media-desktop-wide {
    padding-block: 115px 160px;
  }
}

  @include media-tablet  {
  .projects__box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.projects__title {
  margin: 0;
  padding: 0 0 12px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 69px;
  color: $_secondary_black;
  letter-spacing: -2px;

  @include media-tablet  {
    font-size: 50px;
    line-height: 69px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    font-size: 55px;
    line-height: 69px;
  }
}

.projects__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include media-tablet  {
    justify-content: end;
    margin-top: -50px;
  }
}
