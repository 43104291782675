.project {
  background-color: $_main_dark;
}

.project__container {
  margin: 0 auto;
  @include main-container-padding;
}

.project__title {
  margin: 0;
  padding: 16px 0 18px;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 69px;
  letter-spacing: -1px;
  color: $_main_light;

  background-color: $_main_dark;

  @include media-tablet {
    padding: 54px 0 70px;

    font-size: 40px;
    letter-spacing: -2px;

  }

  @include media-desktop {
    padding: 105px 0 44px;

    font-size: 55px;
    letter-spacing: -3px;
  }

  @include media-desktop-wide {
    padding-top: 90px;
  }
}

.project__box-info {
  border-top: 2px solid rgba(225, 241, 227, 0.6);
  padding-bottom: 53px;
  padding-top: 53px;

  @include media-tablet {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    padding-top: 40px;
  }

  @include media-desktop {
    padding-top: 90px;
    padding-bottom: 66px;
  }
}

.project__info {
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  color: $_main_light;
  gap: 30px;
  @include media-tablet {
    width: 60%;
  }

  @include media-desktop {
    width: 70%;
    gap:5%;
    justify-content:unset;
  }
}

.project__feature-box {
  width: 45%;

  @include media-desktop {
    width: 30%;
        display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.project__feature-box--order {
  @include media-desktop {
    order: -1;
  }
}

.project__item-info {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 52px;
  letter-spacing: -1px;
  color: $_main_light;

  @include media-tablet {
    font-size: 32px;
  }

  @include media-desktop {
    font-size: 36px;
  }
}

.project__feature {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-transform: lowercase;
  color: $_main_light;

  @include media-tablet {
    font-size: 14px;
  }

  @include media-desktop {
    font-size: 16px;
  }
}

.project__list-of-works {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @include list-style-reset;
  padding-bottom: 53px;

  color: $_main_light;
}

.project__item-of-works {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 149%;
  color: $_main_light;

  @include media-tablet {
    font-size: 16px;
  }

  @include media-desktop {
    font-size: 18px;
    letter-spacing: -1px;
  }
}

.project__text-box {
  padding: 84px 0 86px;

  border-top: 2px solid rgba(225, 241, 227, 0.6);

  @include media-tablet {
    padding: 46px 0;
  }

  @include media-desktop {
    display: flex;

    padding: 93px 0 117px;
  }

  @include media-desktop-wide {
    padding: 44px 0 197px;
  }
}

.project__text {
  margin: 0;
  padding: 0;

  color: $_white;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 149%;
}

.project__text--small {
  padding: 0 35px 33px 0;

  @include media-tablet {
    width: 315px;
  }
}

.project__text--large {
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: -1px;
  color: $_white;

  @include media-tablet {
    width: 600px;
    margin-left: auto;

    font-size: 28px;
    line-height: 128%;
  }
}

.project__info--project2 {
  padding: 60px 0 0;
}
