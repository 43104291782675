.project-text {
  padding: 40px 16px;

  @include media-tablet {
    display: flex;
    gap: 24px;
    align-items: center;

    padding: 118px 32px 88px;
  }

  @include media-desktop {
    padding: 118px 20px;
  }

  @include media-desktop-wide {
    gap: 128px;

    padding: 118px 108px;
  }

  @include media-desktop-extra-wide {
    padding: 118px 350px;
  }
}

.project-text__box {
display: block;

  @include media-tablet {
    display: flex;
    gap: 128px;
  }
  @include media-desktop-extra-wide {
    max-width: 1224px;
    margin: 0 auto;
  }
}


.project-text__content {
  margin: 0 0 40px;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-tablet {
    order: 2;
  }

  @include media-desktop {
    font-size: 18px;
    line-height: 24px;
  }

  @include media-desktop-wide {
    min-width: 496px;
  }
}

.project-text__image {
  display: block;

  @include media-tablet {
    min-width: 50vw;
    height: auto;

    order: 1;
  }

  @include media-desktop {
    min-width: 45vw;
  }

  @include media-desktop-wide {
    min-width: 30vw;
    max-width: 496px;
  }

  @include media-desktop-extra-wide {
    min-width: 18vw;
    max-width: 496px;
  }
}
