.navigation {
  z-index: 150;
  width:100%;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 80px;
  bottom: 0px;
  background-color: $_main_dark;
}

.navigation__list {
  display: flex;
  flex-direction: column;
  gap: 0px;
  z-index:2;
  @include list-style-reset;
  min-width: $mobile;
  max-width: 560px;
  padding-top:21px;
  padding-left:65px;
  letter-spacing:-3px;
  margin:auto;
  width:100%;

  &::before {
    content:"";
    width: 100%;
    height: 100vh;
    position: absolute;
    left:max(16px, 50vw - 285px);
    top:-4px;
    z-index:-1;
    background-image: url("data:image/svg+xml, %3Csvg fill='none' stroke='%235D9F93' xmlns='http://www.w3.org/2000/svg' id='icon-plus' viewBox='0.9 0.9 24.2 24.2'%3E%3Cg stroke-width='0.07' fill-rule='evenodd'%3E%3Cg id='Learn' transform='translate(-249.000000, -3678.000000)'%3E%3Cg id='Group-6' transform='translate(250.000000, 3679.000000)'%3E%3Cpath d='M24,24 L12.0006916,24 C5.40141779,24 0,18.5999654 0,12.0006916 C0,5.40141779 5.40141779,0 12.0006916,0 C18.5999654,0 24,5.40141779 24,12.0006916 L24,24 Z' vector-effect='non-scaling-stroke' stroke-width='2px'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 760px 760px;
    transform: rotate(180deg);
  }
}

.navigation__item {
  padding: 10px;
}

.navigation__link {
  display: inline-block;
  min-height: 35px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 70px;
  color: $_font_grass;
  cursor: pointer;

  &:hover {
    color: $_white;
    font-weight: 400;
  }

  &--active {
    display: inline-block;
    min-height: 35px;
    font-family: $_f__common;
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 70px;
    color: $_main_light;
    font-weight: 300;
  }

  &--active:hover {
    cursor: default;
  }
}
