
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  min-height: 50px;
  padding: 12px 36px;
  background: $_button_dark;
  border-radius: 64px;
  border: 0;
  text-align: center;
  vertical-align: middle;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $_white;
  cursor: pointer;

  @include media-tablet  {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -1px;
  }
}
.projects__container .button
{
width:auto;
min-width:200px;
}
.button:hover {
  background-color: $_button_hover;
}

.form__btn-submit {
  width: 175px;
  z-index: 5;
}

.about-opportunities__link {
  padding: 13px 55px;
  width: 210px;
}
