.project-2-slider {
  position: relative;
  padding: 0 16px 0;
  background: linear-gradient(180deg, rgba(0,49,55,1) 50%, rgba(0,49,55,1) 50%);


  @include media-tablet {
    background: linear-gradient(180deg, rgba(0,49,55,1) 50%, rgba(225,241,227,1) 50%);
  }

  @include main-container-padding;
}

.project-2-slider__box {
  position: relative;
  margin: 0 auto;
  max-width: 1224px;

    &:after,
    &:before {
      position: absolute;
      content: "";
      width: 28px;
      height: 7px;
      right: 6px;
      top: 50%;
      z-index: 5;
      transform: translateY(-50%);
    }

    &:after {
    background-image: url("../img/project-arrow-mob.svg");
  }

    &:before {
    background-image: url("../img/project-arrow-mob-left.svg");
    left: 6px;
  }
}

.project-2-slider__nav-list {
  @include list-style-reset;
  display: flex;
  gap: 9px;

  position: absolute;
  left: 50%;
  bottom: 20px;

  transform: translateX(-50%);
}

.project-2-slider__nav-btn {
  display: block;
  width: 10px;
  height: 10px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  background-image: url("../img/project-slider-point.svg");
}

.project-2-slider__nav-btn--active {
  background-image: url("../img/project-slider-active-point.svg");
}

.project-2-slider__picture {
  position: relative;

  &:after,
  &:before {
    position: absolute;
    content: "";
    top: 0;
    width: 27%;
    height: 100%;
    background: linear-gradient(94.29deg, rgba(0, 49, 55, 0.6) 0.06%, rgba(243, 241, 216, 0) 87.25%);
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    transform: rotate(-180deg);
  }
}

.project-2-slider__image {
  display: block;
}

.project-slider--project2 {
  background: linear-gradient(180deg, rgba(0,49,55,1) 50%, rgba(0,49,55,1) 50%);
}
