.slick-slider {
  position: relative;
}

.slick-prev {
  position: absolute;
  content: "";
  width: 27%;
  height: 100%;

  font-size: 0;

  left: 0;
  top: 0;
  z-index: 5;

  background: linear-gradient(94.29deg, rgba(0, 49, 55, 0.6)  0.06%, rgba(243, 241, 216, 0) 87.25%);
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;

  cursor: url(../img/project-arrow-mob-left.svg),e-resize;

  @include media-desktop {
    cursor: url(../img/slider-arrow-left-cursor.svg),e-resize;
  }

}

.slick-next {
  transform:  rotate(180deg);
  position: absolute;
  content: "";
  width: 27%;
  height: 100%;

  font-size: 0;

  right: 0;
  top: 0;
  z-index: 5;

  background: linear-gradient(94.29deg, rgba(0, 49, 55, 0.6) 0.06%, rgba(243, 241, 216, 0) 87.25%);
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;

  cursor: url(../img/project-arrow-mob.svg),e-resize;

  @include media-desktop {
    cursor: url(../img/slider-arrow-right-cursor.svg), e-resize;
  }
}

.slick-dots {
  transform: translateX(-50%);
  position: absolute;
  bottom: 20px;
  left: 50%;
  @include list-style-reset;
  display: flex !important;
  justify-content: center;
  gap: 10px;
}

.slick-dots li button{
  display: block;
  width: 10px;
  height: 10px;

  font-size: 0;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  background-image: url("../img/project-slider-point.svg");
  border: none;

  @include media-tablet {
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
  }

  @include media-desktop-wide {
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
  }
}

.slick-dots li.slick-active button {
  background-image: url("../img/project-slider-active-point.svg");

  @include media-tablet {
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
  }

  @include media-desktop-wide {
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
  }
}
