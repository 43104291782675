.about-owner {
  /**/ background-color: #bebbad;
  background-image: url('../img/about-traviss-moss-01.jpg');
  background-size: auto 440px;
  background-position: bottom left;
  background-repeat: no-repeat;
  padding-bottom: 380px;

  @include media-tablet {
    display: flex;
    background-position: left bottom;
    background-size: contain;
    padding-bottom: 0;
  }
}

.about-owner__box {
  @include main-container-padding;
  @include media-tablet {
    display: flex;
  }
}

.about-owner__container {
  padding-block: 46px 37px;

  @include media-tablet {
    padding-block: 60px 113px;
    width: 50%;
    order: 1;
  }

  @include media-desktop {
    padding-block: 164px 178px;
  }

  @include media-desktop-wide {
    padding-block: 160px 150px;
    padding-left: 0;
  }
}

.about-owner__title {
  margin: 0 0 27px;
  padding: 0;
  width: 200px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: -2px;
  color: $_main_dark;

  @include media-tablet {
    margin-left: -50px;
    margin-bottom: 42px;
    min-width: 325px;

    font-size: 50px;
    line-height: 56px;
    letter-spacing: -3px;
  }

  @include media-desktop {
    margin-left: -108px;
    margin-bottom: 73px;
    min-width: 590px;

    font-size: 55px;
    line-height: 69px;
}
}

.about-owner__text-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 0 61px;
  padding: 0;
}

.about-owner__text {
  margin: 0;
  padding: 0;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.5px;
  color: $_main_dark;

  @include media-desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.about-owner__cite {
  display: flex;
  flex-direction: column;
}

.about-owner__cite-author {
  margin-bottom: 5px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 24px;
    line-height: 135%;
  }
}

.about-owner__cite-position {
  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -1px;
  color: $_main_dark;

  @include media-tablet {
    font-size: 20px;
    line-height: 135%;
  }
}

.about-owner__image-wrapper {
  @include media-tablet {
    min-height: 500px;
    width: 100%;
  }
}

/* .about-owner__image-wrapper {
  min-height: 500px;
  width: 100%;
  background-image: url('../img/about-traviss-moss-01.jpg');

  about-mob-nicholas-traviss-bg.png
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  @include media-tablet {
    width: 50%;
    display: flex;
    align-items: flex-end;
    background-image: none;
  }
}

.about-owner__image {
  display: block;
  object-fit: cover;

  @include media-tablet {
    width: 100%;
    height: auto;
  }
}*/
