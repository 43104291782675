.project-slider {
  position: relative;
  margin: 0 auto;
  padding: 0 16px 0;
  background: linear-gradient(180deg, rgba(0,49,55,1) 50%, rgba(225,241,227,1) 50%);

  @include main-container-padding;
}

.project-slider__box {
  @include media-desktop-extra-wide {
    margin: 0 auto;
    max-width: 1224px;
  }
}

.project-slider__nav-list {
  @include list-style-reset;
  display: flex;
  gap: 9px;

  position: absolute;
  left: 50%;
  bottom: 20px;

  transform: translateX(-50%);
}

.project-slider__nav-btn {
  display: block;
  width: 10px;
  height: 10px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  background-image: url("../img/project-slider-point.svg");
}

.project-slider__nav-btn--active {
  background-image: url("../img/project-slider-active-point.svg");
}

.project-slider__image {
  display: block;
}

.project-slider--project2 {
  background: linear-gradient(180deg, rgba(0,49,55,1) 50%, rgba(0,49,55,1) 50%);
}
