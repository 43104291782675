.biome {
  background-color:$_main_dark;
}

.biome__container {
  margin: 0 auto;
}

.biome__container--up {
  position: relative;
  min-height: 470px;
  @include main-container-padding;
  padding-block: 48px 290px;
  z-index: 5;

  @include media-tablet {
    min-height: 550px;
    padding-block: 78px 460px;
  }

  @include media-desktop {
    min-height: 650px;
    padding-block: 58px 480px;
  }

  @include media-desktop-wide {
    min-height: 800px;
    padding-block: 58px 590px;
  }
}

.biome__container--down {
  min-height: 572px;
  @include main-container-padding;
  padding-block: 135px 88px;

  @include media-tablet {
    padding-block: 164px 93px;
  }

  @include media-desktop {
    min-height: 537px;
    padding-block: 238px 116px;
  }

  @include media-desktop-wide {
    min-height: 500px;
    padding-block: 226px 91px;
  }
}

.biome__title {
  margin: 0;
  padding: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: $_main_dark;
  letter-spacing: -3px;

  z-index: 5;

  @include media-tablet {
    font-size: 48px;
    line-height: 63px;
  }

  @include media-desktop {
    font-size: 64px;
    line-height: 77px;
  }

  @include media-desktop-wide {
    width: 1016px;
  }
}

.biome__wrapper {
  position: relative;
  background-color: $_secondary_light;
}

.biome__wrapper::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: url('../img/biom.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  filter: blur(14px);
  opacity: 0.3;
}

.biome__image {
  display: block;
  width: 328px;
  position: absolute;
  bottom: -85px;
  left:calc(50% - 164px);
  z-index: 5;

  @include media-tablet {
    width: 704px;
    height: 485px;
    left:calc(50% - 352px);
    bottom: -115px;
  }

  @include media-desktop {
    width: 984px;
    height: 587px;
    left:calc(50% - 492px);
    bottom: -155px;
  }

  @include media-desktop-wide {
    width: 1222px;
    height: 730px;
    left:calc(50% - 611px);
    bottom: -175px;
  }
}

.biome__content-box {

  @include media-tablet {
    width: 704px;
    margin: 0 auto;
  }

  @include media-desktop {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 984px;
  }

  @include media-desktop-wide {
    width: 1223px;
  }

}

.biome__text {
  margin: 0;
  padding: 0 0 57px;
  font-family: $_f__common;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 140%;
  color: $_main_light;
  letter-spacing: -1px;

  @include media-tablet {
    padding: 0 0 73px;

    font-size: 32px;
    line-height: 128%;
  }

  @include media-desktop {
    width: 704px;
  }
}

.biome__button {
  display: block;
}
