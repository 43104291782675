@mixin list-style-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin main-container-padding {
  padding: 0 16px;

  @include media-tablet {
    padding: 0 32px;
  }

  @include media-desktop {
    padding: 0 20px;
  }

  @include media-desktop-wide {
    padding: 0 108px;
  }

  @include media-desktop-extra-wide {
    padding: 0 348px;
  }
}
