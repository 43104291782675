.main {
  padding-top:81px;

  @include media-tablet {
    padding-top: 100px;
  }

  @include media-desktop  {
    padding-top:0px;
  }
}

.main__wrapper {
  max-width: $mobile-container-max;

  @include media-tablet  {
    max-width: $tablet-container-max;
  }

  @include media-desktop {
    max-width: $desktop-container-max;
  }

  @include media-desktop-wide {
    max-width: $desktop-wide-container-max;
  }
}

.main__container {
  background-color: $_main_dark;
  overflow:hidden;
}

.main__image {
  display: block;
  aspect-ratio:16 / 9;
}

.main__banner-picture {
  @include media-tablet  {
    background: linear-gradient(180deg, rgba(0, 49, 55, 0.4) 0%, rgba(0, 49, 55, 0) 90.32%);
  }
}

.main__banner {
  overflow: hidden;
  position: relative;
  z-index: 5;
  width: 100%;
  height: calc(100vw / 1.83);
  max-height: 100vh;
  border-width: 0;
  outline-width: 0;

  @include media-tablet {
    height: calc(100vw / 1.81);
  }

  @include media-desktop {
    height: calc(100vw / 1.80);
    background: linear-gradient(180deg, rgba(0, 49, 55, 0.4) 0%, rgba(0, 49, 55, 0) 90.32%);
  }
}

.main__banner::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 38vh;
  background: linear-gradient(180deg, rgba(0, 49, 55, 0.4) 0%, rgba(0, 49, 55, 0) 90.32%);
  background-repeat: no-repeat;

}

.main__picture {
  @include media-tablet {
    display: none;
  }
}

.main__play-sing {
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 22px);
  width:45px;
  height:45px;
  cursor: pointer;

    @include media-tablet {
      top: calc(50% - 45px);
      left: calc(50% - 45px);
      width:90px;
      height:90px;
  }
    @include media-desktop {
      top: calc(50% - 65px);
      left: calc(50% - 65px);
      width:130px;
      height:130px;
  }
      @include media-desktop-wide {
      display:none;
  }

}

.main__play-sing--project2 {
  display: none;
}
