.card {
  padding-bottom: 46px;
}
/*
312 80 312
392 24 288    = 312
704 = 100px
45% 10% 45%
55% 45%
*/
@include media-tablet  {
  .card:nth-child(4n+1) {/*первый*/
    width: 55%;
    padding-inline-end: 10%;
  }
  .card:nth-child(4n+2) { /*второй справа*/
    width: 45%;
    padding-top:5%;
  }
  .card:nth-child(4n) {
    width: 55%;
  }
  .card:nth-child(4n+3) { /* последний */
    width: 45%;
    padding-inline-start:4%;
    order:3;
    margin-top:-25%;
  }
  /*.card:nth-child(4n+3) {order:3; padding-left: 20%;}*/
}

.card__button:hover {
  background-color: $_button_hover;
  border-color: $_button_hover;
}

.card__image {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.card__text {
  padding: 5px 0 0;
  margin: 0;

  font-family: $_f__common;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: $_secondary_black;
  letter-spacing: -1px;

  @include media-desktop {
    font-size: 24px;
  }
}

.card__link:hover .card__image {
  transition-duration: 0.3s;
  filter: blur(17px);
}

.card__link {
  display:block;
  overflow:hidden;
  position:relative;
}

.card__button {
  background:transparent;
  position:absolute;
  top:calc(50% - 25px);
  z-index:100;
  border: 1px solid $_white;
  cursor:pointer;
  visibility:hidden;
  left: 0;
  right: 0;
  margin-inline: auto;
}

.card__link:hover .card__button {
  visibility:visible;
}
