*, *::before, *::after {
  box-sizing: inherit;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: $_f__common;
  font-weight: normal;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.hidden {
  position: absolute;
  width: 0.1px !important;
  height: 0.1px !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  z-index: -1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

section > * > *, .footer > * > *,  .projects__card > * > *, .projects__title > * > *
{
opacity:0;}

.main__wrapper.projects > * > * {opacity:1;}

:before
{
pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

[dir=rtl] *:before,
[dir=rtl] *:after
{

       -moz-transform: scale(-1, 1);
-webkit-transform: scale(-1, 1);
-o-transform: scale(-1, 1);
-ms-transform: scale(-1, 1);
transform: scale(-1, 1);
        }
        
[dir=rtl] .navigation__list::before,
[dir=rtl] .form::before
{
 transform: rotate(180deg) scale(-1,1);
}
        


[dir=rtl] .about-owner__box {
    display: flex;
    flex-direction: row-reverse;
}

@media (pointer: none) {
.slick-arrow
{
pointer-events:none;
}}